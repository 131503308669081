import './index.scss';
import 'i18n'

import * as Sentry from '@sentry/react';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import { IsCompiledApp, IsFoodSpot } from 'core/availableFunctional';
import React, { Suspense, lazy } from 'react';

import { BrowserTracing } from '@sentry/tracing';
import { Elements } from '@stripe/react-stripe-js';
import ErrorBoundary from 'app/views/components/ErrorBoundary';
import { Preloader } from './app/views/components/Preloader/Preloader';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import { loadStripe } from '@stripe/stripe-js';
import reportWebVitals from './reportWebVitals';
import { sentryIgnoreErrors } from 'sentryIgnoreErrors';
import { setupPWA } from 'setupPWA';

setupPWA()
Sentry.init({
  dsn: 'https://fa59d865781343ceb86a45fde89dc7eb@o4504202079502336.ingest.sentry.io/4504202083827712',
  ignoreErrors: sentryIgnoreErrors,
  beforeSend(event, hint) {
    const error = hint.originalException;
    // @ts-ignore
    if (error && error.message && error.message.match(/Permission denied/i)) {
      event.fingerprint = ["camera unavailable"]
    }
    // @ts-ignore
    if (error && error.message && error.message.match(/Camera stopped. Please restart camera/i)) {
      event.fingerprint = ["Camera stopped"]
    }

    return event
  },
  integrations: [new BrowserTracing()],

  environment: process.env.NODE_ENV,
  // environment: 'crashlytics',
  tracesSampleRate: 1.0,
});
ReactGA.initialize(
  process.env.REACT_APP_IZIPOINT_API_HOST === 'https://api.izipoint.io'
    ? IsCompiledApp ? 'G-3HPN5RESDP' : 'G-B0JW69YYD0'
    : 'G-4HBJ7P52Q4',
  { gaOptions: { siteSpeedSampleRate: 100 } }
);
const App = lazy(() => import('./App'));
const stripepromise = loadStripe(
  process.env.REACT_APP_STRIPE_KEY ??
  'pk_test_51L0jyFFtKshfJlzHYYaZj26WenqJOzZ32EK7mxDFZp4MIAks7dAnmHzzlDAupDE1L6G40cQR5gOWlGbgLQRbxCLN00rovKOshm',
  { locale: IsFoodSpot ? 'lv' : 'en' }
  );

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <Elements stripe={stripepromise}>
      <ErrorBoundary mainComponent={true}>
        <Suspense fallback={<Preloader/>}>
          <App />
        </Suspense>
      </ErrorBoundary>
  </Elements>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
