import { isIOS } from "react-device-detect"

export const GetUserAgreement = (window.navigator.language.toLowerCase().includes('ru') ? process.env.REACT_APP_TERMS_URL_RU : process.env.REACT_APP_TERMS_URL_ENG) || 'https://izipoint.io/ru-ru/agreement'

// Если есть переменная то ставится значение от нее
// Если переменной нет то проверяется на undefined и возращается true (дефолтное значение для пва)
export const IsFoodSpot = process.env.REACT_APP_THEME === 'foodspot'
export const IsBlizko = process.env.REACT_APP_THEME === 'blizko'
export const PhoneAuthAvailability = (process.env.REACT_APP_SHOW_PHONE === 'true' || process.env.REACT_APP_SHOW_PHONE === undefined)

export const YandexPayAvailability = (process.env.REACT_APP_YAPAY_AVAILABILITY === 'true' || process.env.REACT_APP_YAPAY_AVAILABILITY === undefined)

export const SbpAvailability = (process.env.REACT_APP_SBP_AVAILABILITY === 'true' || process.env.REACT_APP_SBP_AVAILABILITY === undefined)

export const GooglePayAvailabilty = (process.env.REACT_APP_GOOGLEPAY_AVAILABILITY === 'true' || process.env.REACT_APP_GOOGLEPAY_AVAILABILITY === undefined)

export const ApplePayAvailability = (process.env.REACT_APP_APPLEPAY_AVAILABILITY === 'true' || process.env.REACT_APP_APPLEPAY_AVAILABILITY === undefined)

export const TelegramAvailable = (process.env.REACT_APP_TELEGRAM === 'true' || process.env.REACT_APP_TELEGRAM === undefined)
export const WhatsAppAvailable = (process.env.REACT_APP_WHATSAPP === 'true' || process.env.REACT_APP_WHATSAPP === undefined)
export const FBMessangerAvailable = process.env.REACT_APP_FBMESSANGER === 'true'

export const GLOBAL_THEME = process.env.REACT_APP_THEME || 'izipoint'

export const IsBrandedApp = !process.env.REACT_APP_IZIPOINT_HOST?.includes('izipoint.io')

export const IsCompiledApp = isIOS ? "standalone" in navigator && navigator.standalone === true : (window.matchMedia('(display-mode: standalone)').matches && navigator.userAgent.toLowerCase().includes('android'))

