import HttpApi from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { RequestSubmitter } from './core/web/RequestSubmitter/index';
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

i18next
.use(initReactI18next)
.use(LanguageDetector)
.use(HttpApi)
.init({
    // supportedLngs: ['ru', 'en'],
    fallbackLng: {
        'ky': ['ru'],
        'be': ['ru'],
        'kk': ['ru'],
        'uk': ['ru'],
        'lv': ['lv'],
        'default': ['en'],
    },
        keySeparator: '.',
        detection: {
            order: ['localStorage', 'navigator'],
            caches: ['localStorage'],
        },
        interpolation: {
            escapeValue: false,
        },
        backend: {
            loadPath: '/assets/locales/{{lng}}/translation.json',
        },
    })

    export default i18next